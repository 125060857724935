import React from "react";
import SolutionsPage from "../pages/SolutionsPage";
import dytaskData from "../translation/solutions/dytask.json";
import DytaskHero from "../assets/images/solutions/dytaskHero.png";
import EstateView from "../assets/images/industries/estateView.png";
import Officer from "../assets/images/industries/officer.png";
import { IconMapPinFilled, IconChartPieFilled, IconRoute } from "@tabler/icons-react";

// Icon mapping object
const iconMapping = {
  IconMapPinFilled: IconMapPinFilled,
  IconChartPieFilled: IconChartPieFilled,
  IconRoute: IconRoute,
};

// Image mapping object
const imageMapping = {
  EstateView: EstateView,
  Officer: Officer,
};

function Dytask() {
  return (
    <SolutionsPage 
      data={dytaskData}
      heroImage={DytaskHero}
      iconMapping={iconMapping}
      imageMapping={imageMapping}
      translationKey="dytask"
    />
  );
}

export default Dytask;
