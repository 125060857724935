import React from "react";
import Nav from "../components/Nav";
import FooterCertificates from "../components/FooterCertificates";
import Footer from "../components/Footer";
import { useLanguage } from "../config/LanguageContext";
import { Link, useNavigate } from "react-router-dom";

// Reusable SolutionPage Component
function SolutionPage({ data, heroImage, iconMapping, imageMapping, translationKey }) {
  const { currentLanguage, translations } = useLanguage();
  const navigate = useNavigate();

  const handleBookDemoClick = () => {
    navigate("/bookdemo");
  };

  // Safe translation access
  const headerButtonText =
    translations?.[translationKey]?.[currentLanguage]?.headerButton || "Request a Demo";

  return (
    <>
      <Nav />
      <div className="solution-landing-container">
        <div className="solution-landing-context">
          <div>
            <h1>{data.title}</h1>
            <h2>{data.subTitle}</h2>
            <button className="btn-white btn-large" onClick={handleBookDemoClick}>
              {headerButtonText}
            </button>
          </div>
        </div>
        {heroImage && (
          <div className="solution-landing-image-container">
            <img src={heroImage} alt={data.title} />
          </div>
        )}
      </div>

      <span className="wraps">
        <div className="right-to-left-triangle-white"></div>
      </span>

      {/* Deployed As Section */}
      <div className="deployedAs-container">
        <h3>Deployed As</h3>
        <div className="deployedAs-item-wrapper">
          {data.deployedAs.map((item, index) => {
            const IconComponent = iconMapping[item.icon]; // Dynamically map icon

            return (
              <div key={index} className="deployedAs-item">
                <div className="deployedAs-icon-container">
                  {IconComponent && <IconComponent size={64} />}
                </div>
                <div className="deployedAs-text-container">
                  <p>{item.text}</p>
                </div>
              </div>
            );
          })}
        </div>
      </div>

      <h3 className="key-features">Key Features</h3>

      {/* Features Section */}
      <div className="solution-features-container">
        {data.features.map((feature, index) => {
          const isReversed = index % 2 !== 0;
          const lastIndex = index === data.features.length - 1;
          const isFirst = index === 0;

          return (
            <div
              key={index}
              className={`solution-feature ${isReversed ? "reversed" : ""}`}
              style={{
                clipPath: isFirst
                  ? "polygon(0 0%, 100% 0, 100% 100%, 0 95%)"
                  : lastIndex
                  ? isReversed
                    ? "polygon(0px 0%, 100% 25px, 100% 100%, 0px 100%)"
                    : "polygon(0px 5%, 100% 0%, 100% 100%, 0px 100%)"
                  : "",
              }}
            >
              <div className="feature-text">
                <h3>{feature.title}</h3>
                <span>{feature.description}</span>
                {feature.buttonLink && (
                  <Link to={feature.buttonLink}>
                    <button
                      className={`btn-${isReversed ? "secondary-outline-large" : "white-outline-large"} btn-medium`}
                    >
                      View more
                    </button>
                  </Link>
                )}
              </div>
              <div
                className="feature-image"
                style={{
                  backgroundImage: `url(${imageMapping[feature.image]})`,
                }}
              >
                <div className="image-overlay"></div>
              </div>
            </div>
          );
        })}
      </div>

      <FooterCertificates />
      <Footer />
    </>
  );
}

export default SolutionPage;
