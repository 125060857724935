export const landingTranslations = {
 "en": {
    header: "The best way to predict the future is to invent it",
    paragraph: "Alan Curtis Kay (1971), one of the fathers of modern computing",
    ctaButton: "See how",
    softwareSolutions: "Innaxys started in Public Safety, providing mission-critical solutions to support firearms operations, digital evidence management and evidence-based policing. Today we continue to innovate with solutions for public safety, government agencies, universities and research and more recently for aviation. ",
    solutionsContext: "Rooted in Public Safety",
    solutionsText: "Innaxys combines innovation and systems. Our journey started fourteen years ago, developing science-based software solutions for policing. Today we apply the same recipe for all our clients, providing cross-sector innovation and mission-critical architecture. ",
    solutionsText2: "Today we support Criminal Justice and Public Safety, Government organisations, Universities and the Aviation industry.",
    drivingSeat: "Putting users in the driving seat",
    drivingSeatText1: "At a time when large tech suppliers frequently expect all their clients to use the same solution and adapt uniformly to their products, we take a different approach as a human-size software house.",
    drivingSeatText2: "The Innaxys ethos is to work closely in partnership with clients to customise and develop a personalised software experience. You set the roadmap, and we develop it.",
    evidence: "Investing in Britain",
    evidenceContext: "Whilst our CEO is French, we are a British company committed in our investment to the United Kingdom and its communities. All our software is developed by our close-knit team in the heart of Derbyshire, and we are a proud member of the Made in Britain initiative in support of our country’s economy.",
     bannerItems: [
      { "label": "Police", "icon": "police", "link": "/police" },
      { "label": "Fire & Rescue", "icon": "fire and rescue", "link": "/fire" },
      { "label": "Government", "icon": "government", "link": "/govUni" },
      { "label": "Education", "icon": "education", "link": "/govUni" },
      { "label": "Aviation", "icon": "aviation", "link": "/aviation" }
    ]
  },
  "fr": {
    header: "Le meilleur moyen de prédire l'avenir est de l'inventer",
    paragraph: "Alan Curtis Kay (1971) est un informaticien américain, peu connu du public, mais dont le travail pionnier sur la programmation orientée objet et les interfaces graphiques en fenêtres a révolutionné l'industrie du logiciel et la façon dont nous interagissons avec les applications aujourd'hui.",
    ctaButton: "Contactez-nous",
    softwareSolutions: "Solutions logicielles pour",
    solutionsContext: "Innaxys est fière de développer des solutions logicielles qui aident les forces de police et les autres organismes de sécurité publique à fournir un meilleur service à la communauté, de manière plus efficace et avec les meilleurs renseignements en main. Nous utilisons la science, l'expérience et les technologies de pointe pour développer des solutions complètes qui soutiennent les agents dans la rue grâce à la technologie mobile, ainsi que les équipes de gestion et les analystes grâce à des applications basées sur le cloud.",
    drivingSeat: "Placer les utilisateurs aux commandes",
    drivingSeatText1: "À une époque où les grands fournisseurs de technologies s'attendent souvent à ce que tous leurs clients utilisent la même solution et s'adaptent uniformément à leurs produits, nous adoptons une approche différente en tant que petite maison de logiciels.",
    drivingSeatText2: "L'éthique d'Innaxys est de travailler en étroite collaboration avec les clients pour personnaliser et développer une expérience logicielle sur mesure. Vous définissez la feuille de route, et nous la développons.",
    evidence: "Investir en Grande-Bretagne",
    evidenceContext: "Bien que notre PDG soit français, nous sommes une entreprise britannique engagée dans son investissement au Royaume-Uni et dans ses communautés. Tous nos logiciels sont développés par notre équipe soudée au cœur du Derbyshire, et nous sommes fiers d’être membre de l’initiative Made in Britain pour soutenir l’économie de notre pays.",
     bannerItems: [
      { "label": "Police", "icon": "police", "link": "/industries/police" },
      { "label": "Fire & Rescue", "icon": "fire and rescue", "link": "/industries/fire-rescue" },
      { "label": "Government", "icon": "government", "link": "/industries/government" },
      { "label": "Education", "icon": "education", "link": "/industries/education" },
      { "label": "Aviation", "icon": "aviation", "link": "/industries/aviation" }
    ]
  }
};
