import React from "react";
import SolutionPage from "../pages/SolutionsPage";
import tramData from "../translation/solutions/tram.json";
import TramHero from "../assets/images/solutions/tramHero.png";
import Firearms from "../assets/images/solutions/firearms.jpg";
import FireFighters from "../assets/images/solutions/firefighters.png";
import Scientist from "../assets/images/solutions/scientist.png";
import Airworthy from "../assets/images/solutions/airworthy.png";
import {
  IconUserShield,
  IconFlame,
  IconFlaskFilled,
  IconPlane,
  IconPhotoFilled,
} from "@tabler/icons-react";

const iconMapping = {
  IconUserShield,
  IconFlameFilled: IconFlame,
  IconFlaskFilled,
  IconPlaneFilled: IconPlane,
  IconPhotoFilled,
};

const imageMapping = {
  Firearms,
  FireFighters,
  Scientist,
  Airworthy,
};

function Tram() {
  return (
    <SolutionPage
      data={tramData}
      heroImage={TramHero}
      iconMapping={iconMapping}
      imageMapping={imageMapping}
      translationKey="tram"
    />
  );
}

export default Tram;
