import React, { useRef, useEffect, useState } from "react";
import { useLanguage } from "../config/LanguageContext";

const LanguageModal = () => {
  const { currentLanguage, handleLanguageChange } = useLanguage();
  const modalRef = useRef(null);
  const [isOpen, setIsOpen] = useState(true);

  useEffect(() => {
    function handleClickOutside(event) {
      if (modalRef.current && !modalRef.current.contains(event.target)) {
        // Clicked outside the modal, so close it
        closeModal();
      }
    }

    document.addEventListener("mousedown", handleClickOutside);

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [modalRef]);

  const closeModal = () => {
    setIsOpen(false);
  };

  return (
    <>
      {isOpen && (
        <div ref={modalRef} className="language-dropdown">
          <button
            className={`language-button ${
              currentLanguage === "en" ? "active-dropdown-item" : ""
            }`}
            onClick={() => {
              handleLanguageChange("en");
              closeModal();
            }}
            disabled={currentLanguage === "en"}
          >
            <span>English</span>
          </button>
          <button
            className={`language-button ${
              currentLanguage === "fr" ? "active-dropdown-item" : ""
            }`}
            onClick={() => {
              handleLanguageChange("fr");
              closeModal();
            }}
            disabled={currentLanguage === "fr"}
          >
            <span>French</span>
          </button>
        </div>
      )}
    </>
  );
};

export default LanguageModal;
