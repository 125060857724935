import React, { useState, useEffect } from "react";
import { useLanguage } from "../config/LanguageContext";
import Nav from "../components/Nav";
import Footer from "../components/Footer";
import FooterCertificates from "../components/FooterCertificates";
import IndustryDiscover from "../components/IndustryDiscover";
import { Link, useNavigate } from "react-router-dom";
import LandingHero from "../assets/landingHero.png";
import Rooted from "../assets/images/landing/rooted.svg";
import Users from "../assets/images/landing/users.svg";
import Investing from "../assets/images/landing/investing.svg";
import {
  IconBuildingSkyscraper,
  IconFlame,
  IconPlane,
  IconSchool,
  IconShield,
} from "@tabler/icons-react";
import PoliceCarousel from "../assets/images/landing/policeCarousel.jpg";
import FireCarousel from "../assets/images/landing/fireCarousel.jpg";
import EducationCarousel from "../assets/images/landing/educationCarousel.jpg";
import GovernmentCarousel from "../assets/images/landing/governmentCarousel.jpg";
import AviationCarousel from "../assets/images/landing/aviationCarousel.jpg";

function Landing() {
  const { currentLanguage, translations } = useLanguage();
  const navigate = useNavigate();
  const [activeImage, setActiveImage] = useState(0);
  const [hoveredIndex, setHoveredIndex] = useState(null);
  const t = translations.landing[currentLanguage];

  const carouselItems = [
    { icon: IconShield, image: PoliceCarousel, link: "/police" },
    { icon: IconFlame, image: FireCarousel, link: "/fire" },
    { icon: IconBuildingSkyscraper, image: GovernmentCarousel, link: "/government" },
    { icon: IconSchool, image: EducationCarousel, link: "/education" },
    { icon: IconPlane, image: AviationCarousel, link: "/aviation" },
  ];

  useEffect(() => {
    if (hoveredIndex !== null) return;
    const interval = setInterval(() => {
      setActiveImage((prev) => (prev + 1) % carouselItems.length);
    }, 5000);
    return () => clearInterval(interval);
  }, [hoveredIndex]);

  const handleContactUsClick = () => {
    navigate("/contact");
  };

  const landingInformationItems = [
    {
      img: Rooted,
      title: t.solutionsContext,
      text: [t.solutionsText, t.solutionsText2],
    },
    {
      img: Users,
      title: t.drivingSeat,
      text: [t.drivingSeatText1, t.drivingSeatText2],
    },
    { img: Investing, title: t.evidence, text: [t.evidenceContext] },
  ];

  return (
    <>
      <Nav />
      <div className="landing-section">
      <div className="hero-section">
          <div className="hero-bg">
            {carouselItems.map((item, index) => (
              <img
                key={index}
                src={item.image}
                alt="Landing Hero"
                className={`carousel-image ${
                  index === (hoveredIndex !== null ? hoveredIndex : activeImage) ? "active" : ""
                }`}
              />
            ))}
          </div>
          <div className="hero-text-wrapper">
            <h1>{t.header}</h1>
            <span>{t.paragraph}</span>
            <p>{t.softwareSolutions}</p>
          </div>
        </div>

        {/* Banner Section */}
        <div className="banner-container">
          {carouselItems.map((item, index) => {
            const IconComponent = item.icon;
            const isActive = hoveredIndex === index || (hoveredIndex === null && activeImage === index);
            return (
              <div key={index}>
                <Link
                  to={item.link}
                  className={`banner-item ${isActive ? "hover" : ""}`}
                  onMouseEnter={() => setHoveredIndex(index)}
                  onMouseLeave={() => setHoveredIndex(null)}
                >
                  <IconComponent size={48} />
                  {t.bannerItems[index]?.label}
                </Link>
              </div>
            );
          })}
        </div>
      </div>
      <div className="landing-information-container">
        <div className="landing-information-wrapper">
          {landingInformationItems.map((item, index) => (
            <div key={index} className={index % 2 !== 0 ? "reversed" : ""}>
              <img
                style={{ margin: "0 auto" }}
                src={item.img}
                alt={item.title}
              />
              <div>
                <h2>{item.title}</h2>
                {item.text.map((paragraph, idx) => (
                  <p key={idx}>{paragraph}</p>
                ))}
              </div>
            </div>
          ))}
        </div>
      </div>
      <IndustryDiscover />
      <FooterCertificates />
      <Footer />
    </>
  );
}

export default Landing;
