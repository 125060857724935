import React, { useEffect, useState, useRef } from "react";
import Nav from "../components/Nav";
import policeData from "../translation/industries/police.json";
import Firearms from "../assets/images/industries/firearms.png";
import CourtRoom from "../assets/images/industries/courtRoom.png";
import Fireman from "../assets/images/industries/fireman.png";
import EstateView from "../assets/images/industries/estateView.png";
import PoliceHero from "../assets/images/industries/policeHero.png";
import Officer from "../assets/images/industries/officer.png";
import {
  IconUserShield,
  IconRefresh,
  IconClock,
  IconLogout2,
  IconFileSearch,
  IconCloudComputing,
  IconUpload,
  IconDatabase,
  IconCheck,
  IconListCheck,
  IconEdit,
  IconTimelineEvent,
  IconShare,
  IconTrash,
  IconReport,
  IconLock,
  IconBlockquote,
  IconRoute,
  IconAccessPoint,
  IconListDetails,
  IconMap,
} from "@tabler/icons-react";
import IndustryDiscover from "../components/IndustryDiscover";
import FooterCertificates from "../components/FooterCertificates";
import Footer from "../components/Footer";
import { Link } from "react-router-dom";

const iconMapping = {
  IconUserShield,
  IconRefresh,
  IconClock,
  IconLogout2,
  IconFileSearch,
  IconCloudComputing,
  IconUpload,
  IconDatabase,
  IconCheck,
  IconListCheck,
  IconEdit,
  IconTimelineEvent,
  IconShare,
  IconTrash,
  IconReport,
  IconLock,
  IconBlockquote,
  IconRoute,
  IconAccessPoint,
  IconListDetails,
  IconMap,
};

const industryImages = {
  firearms: Firearms,
  courtRoom: CourtRoom,
  fireman: Fireman,
  estateView: EstateView,
  officer: Officer,
};

export default function IndustryPage() {
  const [isSticky, setIsSticky] = useState(false);
  const [activeSection, setActiveSection] = useState("");
  const landingRef = useRef(null);
  const navRef = useRef(null);
  const originalNavOffset = useRef(0);
  const sectionRefs = useRef({});

  useEffect(() => {
    if (navRef.current) {
      originalNavOffset.current = navRef.current.offsetTop + 64;
    }
  }, []);

  const handleScroll = () => {
    const currentScrollPos = window.pageYOffset;
    setIsSticky(currentScrollPos >= originalNavOffset.current - 64);

    for (const [key, ref] of Object.entries(sectionRefs.current)) {
      if (ref && ref.getBoundingClientRect().top <= 100) {
        setActiveSection(key);
      }
    }
  };

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);
    return () => window.removeEventListener("scroll", handleScroll);
  }, []);

  const handleNavClick = (event, targetId) => {
    event.preventDefault();
    const targetElement = document.getElementById(targetId);
    if (targetElement) {
      targetElement.scrollIntoView({ behavior: "smooth", block: "start" });
    }
  };

  return (
    <>
      <Nav />
      <div className="industries-section" ref={landingRef}>
        <div className="industry-landing-container">
          <div className="industry-landing-text">
            <h1>{policeData.title}</h1>
            <h2>{policeData.subtitle}</h2>
            <Link to="/bookdemo">
              <button className="btn-white">Request a demo</button>
            </Link>{" "}
          </div>
          <img
            src={PoliceHero}
            alt={policeData.title}
            className="industry-landing-image"
          />
        </div>

        <div
          ref={navRef}
          className={`industry-nav-container ${isSticky ? "sticky" : ""}`}
        >
          <ul>
            {policeData.featuresList.map((feature, index) => (
              <li
                key={index}
                className={
                  activeSection === feature.link.replace("#", "")
                    ? "active-nav-item"
                    : ""
                }
              >
                <a
                  href={feature.link}
                  onClick={(e) =>
                    handleNavClick(e, feature.link.replace("#", ""))
                  }
                >
                  {feature.name}
                </a>
              </li>
            ))}
          </ul>
        </div>

        <h3 className="industry-h3">Key Features</h3>

        {policeData.featuresList.map((feature, index) => {
          const featureKey = feature.link.replace("#", "");
          const section = policeData[featureKey];
          if (!section) return null;

          return (
            <section
              key={index}
              id={featureKey}
              ref={(el) => (sectionRefs.current[featureKey] = el)}
              className={`industry-feature-container ${
                index % 2 !== 0 ? "reversed" : ""
              }`}
            >
              <div className="industry-feature-context-container">
                <div className="industry-feature-context">
                  <h2>{section.title}</h2>
                  <h3>{section.subTitle}</h3>
                  {section.text.map((paragraph, idx) =>
                    typeof paragraph === "string" ? (
                      <p key={idx}>{paragraph}</p>
                    ) : (
                      <ul key={idx}>
                        {paragraph.list.map((item, listIdx) => (
                          <li key={listIdx}>{item}</li>
                        ))}
                      </ul>
                    )
                  )}
                </div>
                <div
                  className="industry-feature-image-container"
                  style={{
                    backgroundImage: `url(${industryImages[section.image]})`,
                    // mixBlendMode: 'screen'
                  }}
                ></div>
              </div>
              <div className="industry-icons-container">
                {section.icons.map((iconData, idx) => {
                  const IconComponent = iconMapping[iconData.icon];
                  return (
                    <div key={idx} className="industry-feature">
                      <div>
                        <IconComponent color={"#1c2f3e"} />
                      </div>
                      <span>{iconData.text}</span>
                    </div>
                  );
                })}
              </div>
            </section>
          );
        })}
      </div>
      <FooterCertificates />
      <Footer />
    </>
  );
}
