import React from "react";
import SolutionPage from "../pages/SolutionsPage";
import damsData from "../translation/solutions/dams.json"; 
import DeamsHero from "../assets/images/solutions/deamsHero.png"; 
import FireHose from "../assets/images/solutions/fireHose.png"; 
import CourtRoom from "../assets/images/solutions/courtRoom.png"; 
import LionStatue from "../assets/images/solutions/lionStatue.png"; 
import { IconSearch, IconBuildingSkyscraper } from "@tabler/icons-react";

const iconMapping = {
  IconSearch,
  IconBuildingSkyscraper,
};

const imageMapping = {
  CourtRoom,
  FireHose,
  LionStatue,
};

function Dams() {
  return <SolutionPage data={damsData} heroImage={DeamsHero} iconMapping={iconMapping} imageMapping={imageMapping} translationKey="dams" />;
}

export default Dams;
