import React from "react";
import { useLanguage, useNavigation } from "../config/LanguageContext";
import { useNavigate } from "react-router-dom";

function IndustryDiscover() {
  const { currentLanguage, translations } = useLanguage();
  const navigate = useNavigate();

  const handleBookDemoClick = () => {
    navigate("/bookdemo");
  };

  return (
    <>
      <div className="discover-container">
        <h2>
        How can Innaxys help your organisation?
          </h2>
        <p className="secondary">
        Our sales team can walk you through anything you need to know
          </p>
        <button className="cta-button-secondary-large" onClick={handleBookDemoClick}>
          Contact us
        </button>
      </div>
    </>
  );
}

export default IndustryDiscover;
