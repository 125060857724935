export const aboutUsTranslations = {
  en: {
    paragraphs: [
      "Founded in 2011, Innaxys develops and delivers cutting-edge software solutions for emergency services and public sector organisations both in the UK and Europe. Our systems support ground teams with mobile technology, as well as management teams and analysts through cloud-based applications. We harness technology, innovation and forward thinking to design solutions that help our clients to evolve and meet the needs of modern practices, achieve greater efficiencies, minimise paperwork and duplication, improve productivity, and reduce wasted resources. To date, we have developed a number of innovative solutions, both covert as well as commercially available, which are deployed and in use across the UK and worldwide. You can find out more in our Solutions tab.",
      "Our strength is in our size – we are small enough to be flexible and responsive, and to be able to take risks on new technologies, possibilities, and thinking to uncover better approaches and solutions for our clients.",
      "We practice Agile project management methodology, involving our clients at every step of the journey, whether that be for simple configuration of existing solutions to meet ways of working, or a full development project to introduce new functionality. We believe that technology should be a vehicle to deliver better business processes, and that close communication and partnership with the customer is vital to ensure we provide a solution that they actually need and want. This ethos ensures a collaborative approach to every project, builds an environment of trust and transparency, and results in exceptional value for money and positive change for all our clients.",
    ],
    headers: ["Consultancy", "Bespoke Projects", "R & D"],
    consultancy: {
      text: [
        "With many years’ experience working with emergency services all around the world, collaborating with leading crime-science academics and developing mission-critical applications in the most sensitive and secured environments, our team has gained valuable expertise that we can provide to support your project and help you deliver business transformation and solutions through an agile process.",
        "We can offer our services on a consultancy basis to help you with a range of projects, issues and challenges in a cost effective way.",
      ],
      list: [
        "Needs Analysis",
        "Market Analysis",
        "Gap Analysis",
        "Buisness Process Review",
        "Project Design",
        "Requirements Specification",
        "Implementation",
      ],
    },
    solutions: {
      text: [
        "Sometimes in all businesses, a need arises for which there just isn’t a ready-made solution out there. This is where our skills and expertise at Innaxys can really help. We have significant experience in working with clients to design and develop brand-new, cutting edge software and solutions created specifically for individual clients. Our areas of particular expertise include database management, data-mining, geographic information systems, optimisation and more that we can bring to you to develop cloud-based solutions with desktop and mobile clients operating on various operating systems.",
        "We are equally able to design stand-alone software, as well as solutions that interface with third party applications and data sets.",
        "In terms of security, we have delivered solutions accredited up to IL5 level, which equates to the new Top Secret classification. Most of our solutions have been accredited to IL3 (Official).",
      ],
    },
    rnd: {
      text: [
        "At Innaxys we are proud to be able to devote approximately one third of our time to research and development.",
        "Without R&D there would be no innovation - and innovation is key to our ethos.",
        "We work closely with academics, scientists, users and other experts to explore new technologies, possibilities and opportunities to take the valuable outcomes from crime science and bring them to life within our solutions.",
      ],
    },
    descriptionOfJeanClaude: [
      "Originally from France, Jean-Claude has lived in the UK for the last 19 years. He has a long history in the industry, having started as a software developer over 20 years ago before working his way up to Sales and Marketing Director for a medium sized French SME that provides mission critical systems to emergency services.",
      "Following his move to England, Jean-Claude initially worked for Blue 8 Technology Ltd, who were later acquired by Northgate, developing and selling GIS systems to ‘blue-light’ organisations, before leaving to create Innaxys.",
      "Jean-Claude was appointed Honorary Consul of France by the French Government in 2015.",
      "Jean-Claude holds an MSc 2 (Ecole d'Ingenieur EIGSI Violet) in Engineering, he is a qualified aircraft pilot and a keen English car enthusiast with an old Jaguar he has cherished for over 20 years.",
    ],
    family: {
      header: "The Innaxys Family",
      text: "Each member of our carefully selected technical team holds a science related degree, complemented by computer engineering expertise, experience and training. Our engineers are directly employed, and we work closely together from our offices in the heart of Derbyshire. This enables us collectively to bring a dynamic and accomplished approach to software development, driven by a firm focus on the client.",
    },
  },
  fr: {
    paragraphs: [
      "Fondée en 2011, Innaxys développe et fournit des solutions logicielles de pointe pour les services d'urgence et les organisations du secteur public au Royaume-Uni et en Europe. Nos systèmes soutiennent les équipes au sol grâce à la technologie mobile, ainsi que les équipes de gestion et les analystes grâce à des applications basées sur le cloud. Nous exploitons la technologie, l'innovation et la réflexion prospective pour concevoir des solutions qui aident nos clients à évoluer et à répondre aux besoins des pratiques modernes, à atteindre une plus grande efficacité, à minimiser la paperasserie et la duplication, à améliorer la productivité et à réduire le gaspillage des ressources. À ce jour, nous avons mis au point un certain nombre de solutions innovantes, tant secrètes que disponibles dans le commerce, qui sont déployées et utilisées au Royaume-Uni et dans le monde entier. Pour en savoir plus, consultez notre onglet Solutions",
      "Notre force réside dans notre taille - nous sommes suffisamment petits pour être flexibles et réactifs, et pour pouvoir prendre des risques en matière de nouvelles technologies, de possibilités et de réflexion afin de découvrir de meilleures approches et solutions pour nos clients.",
      "Nous pratiquons la méthodologie de gestion de projet Agile, impliquant nos clients à chaque étape du parcours, qu'il s'agisse d'une simple configuration de solutions existantes pour répondre aux méthodes de travail, ou d'un projet de développement complet pour introduire de nouvelles fonctionnalités. Nous pensons que la technologie doit être un moyen d'améliorer les processus d'entreprise, et qu'une communication et un partenariat étroits avec le client sont essentiels pour garantir que nous fournissons une solution dont il a réellement besoin et qu'il souhaite. Cette philosophie garantit une approche collaborative de chaque projet, crée un environnement de confiance et de transparence, et se traduit par un rapport qualité-prix exceptionnel et des changements positifs pour tous nos clients.",
    ],
    consultancy: {
      header: "Conseil",
      text: "Forte de nombreuses années d'expérience auprès des services d'urgence du monde entier, de collaboration avec des universitaires de renom dans le domaine des sciences criminelles et de développement d'applications critiques dans les environnements les plus sensibles et sécurisés, notre équipe a acquis une expertise précieuse qu'elle peut mettre à votre disposition pour soutenir votre projet et vous aider à transformer votre entreprise et à trouver des solutions par le biais d'un processus agile. Nous pouvons vous proposer nos services en tant que consultants pour vous aider à mener à bien toute une série de projets, de questions et de défis de manière rentable.",
      list: [
        "Analyse des besoins",
        "Analyse du marché ",
        "Analyse des lacunes",
        "Examen du processus d'affaires",
        "Conception du projet",
        "Spécification des exigences",
        "Mise en œuvre",
      ],
    },
    solutions: {
      header: "Solutions",
      text: [
        "Dans toutes les entreprises, il arrive qu'un besoin se fasse sentir pour lequel il n'existe pas de solution toute faite. C'est là que les compétences et l'expertise d'Innaxys peuvent vraiment aider. C'est là que les compétences et l'expertise d'Innaxys peuvent vraiment aider.Nous avons une expérience significative dans la collaboration avec les clients pour concevoir et développer de nouveaux logiciels et solutions de pointe créés spécifiquement pour les clients individuels. Nos domaines d'expertise particulière comprennent la gestion de bases de données, l'exploration de données, les systèmes d'information géographique, l'optimisation et plus encore, que nous pouvons mettre à votre disposition pour développer des solutions basées sur le cloud avec des clients de bureau et mobiles fonctionnant sur divers systèmes d'exploitation.",
        "Nous sommes également en mesure de concevoir des logiciels autonomes, ainsi que des solutions qui s'interfacent avec des applications et des ensembles de données de tiers.",
        "En termes de sécurité, nous avons fourni des solutions accréditées jusqu'au niveau IL5, ce qui équivaut à la nouvelle classification Top Secret. La plupart de nos solutions ont été accréditées au niveau IL3 (officiel).",
      ],
    },
    rnd: {
      header: "Recherche & Développement",
      text: [
        "Chez Innaxys, nous sommes fiers de pouvoir consacrer environ un tiers de notre temps à la recherche et au développement.",
        "Sans R&D, il n'y aurait pas d'innovation - et l'innovation est au cœur de notre philosophie.",
        "Nous travaillons en étroite collaboration avec des universitaires, des scientifiques, des utilisateurs et d'autres experts pour explorer de nouvelles technologies, de nouvelles possibilités et de nouvelles opportunités afin d'exploiter les précieux résultats de la science criminelle et de les intégrer dans nos solutions.",
      ],
    },
    descriptionOfJeanClaude: [
      "Originally from France, Jean-Claude has lived in the UK for the last 19 years. He has a long history in the industry, having started as a software developer over 20 years ago before working his way up to Sales and Marketing Director for a medium sized French SME that provides mission critical systems to emergency services.",
      "Following his move to England, Jean-Claude initially worked for Blue 8 Technology Ltd, who were later acquired by Northgate, developing and selling GIS systems to ‘blue-light’ organisations, before leaving to create Innaxys.",
      "Jean-Claude was appointed Honorary Consul of France by the French Government in 2015.",
      "Jean-Claude holds an MSc 2 (Ecole d'Ingenieur EIGSI Violet) in Engineering, he is a qualified aircraft pilot and a keen English car enthusiast with an old Jaguar he has cherished for over 20 years.",
    ],
    family: {
      header: "The Innaxys Family",
      text: "Each member of our carefully selected technical team holds a science related degree, complemented by computer engineering expertise, experience and training. Our engineers are directly employed, and we work closely together from our offices in the heart of Derbyshire. This enables us collectively to bring a dynamic and accomplished approach to software development, driven by a firm focus on the client.",
    },
  },
};
