import React, { useEffect, useState, useRef } from "react";
import Nav from "../components/Nav";
import fireRescueData from "../translation/industries/fire.json"; // Import the fire rescue data
import {
  IconUserShield,
  IconRefresh,
  IconClock,
  IconBellRinging,
  IconLogout2,
  IconFileSearch,
  IconCloudComputing,
  IconUpload,
  IconDatabase,
  IconCheck,
  IconListCheck,
  IconEdit,
  IconTimelineEvent,
  IconShare,
  IconTrash,
  IconReport,
  IconLock
} from "@tabler/icons-react"; // Import necessary icons
import FooterCertificates from "../components/FooterCertificates";
import Footer from "../components/Footer";
import Fireman from "../assets/images/industries/fireman.png"
import FireHero from "../assets/images/industries/fireHero.png"
import FireHose from "../assets/images/solutions/fireHose.png"
import { Link } from "react-router-dom";

// Icon mapping for dynamic rendering
const iconMapping = {
  IconUserShield,
  IconRefresh,
  IconClock,
  IconBellRinging,
  IconLogout2,
  IconFileSearch,
  IconCloudComputing,
  IconUpload,
  IconDatabase,
  IconCheck,
  IconListCheck,
  IconEdit,
  IconTimelineEvent,
  IconShare,
  IconTrash,
  IconReport,
  IconLock
};

const industryImages = {
  Fireman: Fireman,
  FireHose: FireHose,
};

export default function IndustryPage() {
  const [isSticky, setIsSticky] = useState(false);
  const [activeSection, setActiveSection] = useState("");
  const landingRef = useRef(null); // Reference to the landing container
  const navRef = useRef(null); // Reference to the nav container
  const originalNavOffset = useRef(0);
  const sectionRefs = useRef({});

  const industry = fireRescueData; // Access the fire rescue data

  // Ensure fireRescueData is not undefined and has a valid featuresList before using it
  const featuresList = industry?.featuresList || [];

  useEffect(() => {
    if (navRef.current) {
      originalNavOffset.current = navRef.current.offsetTop;
    }
  }, []);

  const handleScroll = () => {
    const currentScrollPos = window.pageYOffset;
    setIsSticky(currentScrollPos >= originalNavOffset.current);

    // Check if any section is in view, update active section
    for (const [key, ref] of Object.entries(sectionRefs.current)) {
      if (ref && ref.getBoundingClientRect().top <= 100) {
        setActiveSection(key);
      }
    }
  };

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);
    return () => window.removeEventListener("scroll", handleScroll);
  }, []);

  const handleNavClick = (event, targetId) => {
    event.preventDefault();
    const targetElement = document.getElementById(targetId);
    if (targetElement) {
      targetElement.scrollIntoView({ behavior: "smooth", block: "start" });
    }
  };

  return (
    <>
      <Nav />
      <div className="industries-section" ref={landingRef}>
        {/* Landing Section */}
        <div className="industry-landing-container">
          <div>
            <h1>{industry.title}</h1>
            <h2>{industry.subtitle}</h2>
<Link to="/bookdemo">
              <button className="btn-white">Request a demo</button>
            </Link>{" "}          </div>
          <img
            src={FireHero}
            alt={industry.title}
            className="industry-landing-image"
          />
        </div>

        <div ref={navRef} className={`industry-nav-container ${isSticky ? "sticky" : ""}`}>
          <ul>
            {featuresList.map((feature, index) => (
              <li
                key={index}
                className={activeSection === feature.link.replace("#", "") ? "active-nav-item" : ""}
              >
                <a
                  href={feature.link}
                  onClick={(e) => handleNavClick(e, feature.link.replace("#", ""))}
                >
                  {feature.name}
                </a>
              </li>
            ))}
          </ul>
        </div>

        <h3 className="industry-h3">Key Features</h3>

        {/* Render all feature sections dynamically */}
        {featuresList.map((feature, index) => {
          const featureKey = feature.link.replace("#", ""); // Extract feature key
          const section = industry[featureKey];

          if (!section) return null; // Skip if section data is missing

          return (
            <section
              key={index}
              id={featureKey}
              ref={(el) => (sectionRefs.current[featureKey] = el)}
              className={`industry-feature-container ${index % 2 !== 0 ? "reversed" : ""}`}
            >
              <div className="industry-feature-context-container">
                <div className="industry-feature-context">
                  <h2>{section.title}</h2>
                  <h3>{section.subTitle}</h3>
                  {section.text.map((paragraph, idx) =>
                    typeof paragraph === "string" ? (
                      <p key={idx}>{paragraph}</p>
                    ) : (
                      <ul key={idx}>
                        {paragraph.list.map((item, listIdx) => (
                          <li key={listIdx}>{item}</li>
                        ))}
                      </ul>
                    )
                  )}
                </div>
                <div
                  className="industry-feature-image-container"
                  style={{ backgroundImage: `url(${industryImages[section.image]})` }}
                ></div>
              </div>

              <div className="industry-icons-container">
                {section.icons.map((iconData, idx) => {
                  const IconComponent = iconMapping[iconData.icon];
                  return (
                    <div key={idx} className="industry-feature">
                      <div>
                        <IconComponent color={"#1c2f3e"} />
                      </div>
                      <span>{iconData.text}</span>
                    </div>
                  );
                })}
              </div>
            </section>
          );
        })}
      </div>

      <FooterCertificates />
      <Footer />
    </>
  );
}
