import React from "react";
import FooterLogoGray from "../assets/Innaxys Logo-Grey.svg";
import { FaLinkedin } from "react-icons/fa6";
import { useNavigate } from "react-router-dom";
import { Link } from "react-router-dom";

function Footer() {
  const navigate = useNavigate();
  const productsFooterData = [
    {
      name: "TRAM - Training Records & Asset Management Solution ",
      path: "/tram",
    },
    {
      name: "DEAMS - Digital Evidence & Asset Management Solution",
      path: "/deams",
    },
    {
      name: "DyTASK - Optimise you workforce and enhance public safety",
      path: "/dytask",
    },
    // {
    //   name: "GIS - Cloud-based Analytical Geographic Information System",
    //   path: "/deams",
    // },
  ];

  const industriesFooterData = [
    {
      name: "Police",
      path: "/police",
    },
    {
      name: "Fire & Rescue",
      path: "/fire",
    },
    {
      name: "Government",
      path: "/government",
    },
    {
      name: "Education",
      path: "/education",
    },
    {
      name: "Aviation",
      path: "/aviation",
    },
  ];

  const companyFooterData = [
    {
      name: "About",
      path: "/about",
    },
    {
      name: "Resources",
      path: "/resources",
    },
    {
      name: "Join",
      path: "/careers",
    },
    {
      name: "Contact",
      path: "/contact",
    },
  ];

  return (
    <section className="footer-wrapper">
      <div className="footer-options-wrapper">
        <div className="innaxys-footer-options">
          <figure className="innaxys-footer-logo">
            <img className="footer-logo" src={FooterLogoGray} alt="Footer Logo" />
          </figure>
          <p>53 Wilmot Street</p>
          <p>Heanor</p>
          <p>Derbyshire, DE75 7EF</p>
          <p>United Kingdom</p>
          <p className="footer-text-divider">+44 1773 765 350</p>
          <p>info@innaxys.com</p>
        </div>
        <div className="footer-options-right">
          <div className="footer-choices">
            <p className="footer-header">Solutions</p>
            <ul>
              {productsFooterData.map((item, index) => (
                <li key={index}>
                  <Link to={item.path}>{item.name}</Link>
                </li>
              ))}
            </ul>
          </div>
          <div className="footer-choices">
            <p className="footer-header">Industries</p>
            <ul>
              {industriesFooterData.map((item, index) => (
                <li key={index}>
                  <Link to={item.path}>{item.name}</Link>
                </li>
              ))}
            </ul>
          </div>
          <div className="footer-choices">
            <p className="footer-header">Company</p>
            <ul className="footer-list-items">
              {companyFooterData.map((item, index) => (
                <li key={index}>
                  <Link to={item.path}>{item.name}</Link>
                </li>
              ))}
            </ul>
          </div>
        </div>
      </div>
      <div className="footer-extra">
        <div className="footer-socials">
          <Link to="https://www.linkedin.com/company/innaxys-ltd/">
            <FaLinkedin className="social-icons" size={24} />
          </Link>
        </div>
        <span className="footer-terms">
          <span className="footer-term-no-access">2024 Innaxys ©</span>
          <div className="footer-divider"></div>
          <Link to="/privacypolicy" className="footer-term">
            Privacy Statement & Terms
          </Link>
        </span>
      </div>
    </section>
  );
}

export default Footer;
