import "./App.css";
import React from "react";
import { HashRouter as Router, Routes, Route } from "react-router-dom"; // BrowserRouter to HashRouter
import Landing from "./pages/Landing";
import Products from "./pages/Products";
import Industries from "./pages/Industries";
import Company from "./pages/Company";
import BookDemo from "./pages/BookDemo";
import Resources from "./pages/Resources";
import PrivacyPolicy from "./pages/PrivacyPolicy";
import CarbonReductionB from "./pages/CarbonReductionB";
import Careers from "./pages/Careers";
import AboutUs from "./pages/AboutUs";
import Contact from "./pages/Contact";
import Tram from "./pages/Tram";
import Dytask from "./pages/Dytask";
import Deams from "./pages/Deams";
import ScrollToTop from "./components/ScrollToTop";
import Gis from "./pages/Gis";
import EssVideo from "./pages/essVideo";
import "@fontsource/geist-sans/100.css";
import "@fontsource/geist-sans/200.css";
import "@fontsource/geist-sans/300.css";
import "@fontsource/geist-sans/400.css";
import "@fontsource/geist-sans/500.css";
import "@fontsource/geist-sans/600.css";
import "@fontsource/geist-sans/700.css";
import "@fontsource/geist-sans/800.css";
import "@fontsource/geist-sans/900.css";
import "@fontsource/bebas-neue";
import { CookiePolicy } from "./pages/CookiePolicy";
import "./styles/landing.css";
import "./styles/solutions.css";
import "./styles/industries.css";
import "./styles/nav.css";
import Police from "./pages/Police";
import Fire from "./pages/Fire";
import Government from "./pages/Government";
import Education from "./pages/Education";
import Aviation from "./pages/Aviation";

function App() {
  return (
    <Router>
      <ScrollToTop>
        <Routes>
          <Route path="/ess-video" element={<EssVideo />} />
          <Route path="/" element={<Landing />} />
          <Route path="/products" element={<Products />} />
          <Route path="/police" element={<Police />} />
          <Route path="/fire" element={<Fire />} />
          <Route path="/government" element={<Government />} />
          <Route path="/education" element={<Education />} />
          <Route path="/aviation" element={<Aviation />} />
          <Route path="/company" element={<Company />} />
          <Route path="/bookdemo" element={<BookDemo />} />
          <Route path="/resources" element={<Resources />} />
          <Route path="/privacypolicy" element={<PrivacyPolicy />} />
          <Route path="/carbon-reduction-23-24"  element={<CarbonReductionB />} />
          <Route path="/careers" element={<Careers />} />
          <Route path="/about" element={<AboutUs />} />
          <Route path="/contact" element={<Contact />} />
          <Route path="/tram" element={<Tram />} />
          <Route path="/dytask" element={<Dytask />} />
          <Route path="/deams" element={<Deams />} />
          <Route path="/gis" element={<Gis />} />
          <Route path="cookiepolicy" element={<CookiePolicy />} />
        </Routes>
      </ScrollToTop>
    </Router>
  );
}

export default App;
