import React, { useState, useEffect } from "react";
import NavLogo from "../assets/InnaxysLogo.svg";
import {
  TbWorld,
  TbMenu2,
  TbCaretDownFilled,
  TbArrowNarrowRight,
  TbX,
} from "react-icons/tb";
import { useNavigate, useLocation } from "react-router-dom";
import LanguageModal from "../components/LanguageModal.jsx";
import { useLanguage } from "../config/LanguageContext.jsx";
import NavDropdownModal from "./navDropdownModal.jsx";
import $ from "jquery";
import {
  IconBuildingSkyscraper,
  IconFlame,
  IconPlane,
  IconSchool,
  IconShield,
} from "@tabler/icons-react";

const Nav = () => {
  const { currentLanguage, handleLanguageChange } = useLanguage();
  const [showLanguageModal, setShowLanguageModal] = useState(false);
  const [showMenu, setShowMenu] = useState(false);
  const [hoveredItem, setHoveredItem] = useState(null);
  const [showDropdown, setShowDropdown] = useState(false);
  const navigate = useNavigate();
  const location = useLocation();

  const isActivePath = (paths) => {
    for (const path of paths) {
      if (location.pathname === path) return true;
    }
    return false;
  };

  const toggleLanguageModal = () => {
    setShowLanguageModal(!showLanguageModal);
  };

  const iconMap = {
    IconUserShield: IconShield,
    IconFlame: IconFlame,
    IconBuildingSkyscraper: IconBuildingSkyscraper,
    IconPlane: IconPlane,
    IconSchool: IconSchool,
    // Add other icon mappings as needed
  };

  const navItems = [
    {
      name: {
        en: "Solutions",
        fr: "Solutions",
      },
      dropdownSections: [
        {
          items: [
            {
              label: "TRAM",
              description: "Training Records & Asset Management Solution",
              path: "/tram",
            },
            {
              label: "DEAMS",
              description: "Digital Evidence & Asset Management Solution",
              path: "/deams",
            },
            {
              label: "DyTASK",
              description:
                "GIS for Policing, with demand prediction and effective patrol and tasking management",
              path: "/dytask",
            },
          ],
        },
      ],
    },
    {
      name: {
        en: "Industries",
        fr: "Industries",
      },
      dropdownSections: [
        {
          header: "Public Safety",
          items: [
            {
              label: "Police",
              icon: "IconUserShield",
              link: "/police",
              subitems: [
                {
                  label: "Firearms Training & Asset Management",
                  link: "/police#fireComp",
                },
                {
                  label: "Digital Evidence & Assets Management",
                  link: "/police#digiEvi",
                },
                {
                  label: "Geographic Information Systems",
                  link: "/police#gis",
                },
                {
                  label: "Predictive Analytics",
                  link: "/police#predict",
                },
              ],
            },
            {
              label: "Fire & Rescue",
              icon: "IconFlame",
              link: "/fire",
              subitems: [
                {
                  label: "Asset Management",
                  link: "/fire#assetManage",
                },
                {
                  label: "Digital Evidence Management",
                  link: "/fire#digiManage",
                },
              ],
            },
          ],
        },
        {
          header: "Government & University",
          items: [
            {
              label: "Government",
              icon: "IconBuildingSkyscraper",
              link: "/government",
              subitems: [
                {
                  label: "Digital Asset Management",
                  link: "/government#digiManage",
                },
              ],
            },
            {
              label: "Education",
              icon: "IconSchool",
              link: "/education",
              subitems: [
                {
                  label: "Asset Management",
                  link: "/education#assetManage",
                },
              ],
            },
          ],
        },
        {
          header: "Aerospace Industry",
          items: [
            {
              label: "Aviation",
              icon: "IconPlane",
              link: "/aviation",
              subitems: [
                {
                  label: "Continued Airworthiness Management",
                  link: "/aviation#assetManage",
                },
              ],
            },
          ],
        },
      ],
    },
    {
      name: {
        en: "Company",
        fr: "Compagnie",
      },
      dropdownSections: [
        {
          items: [
            {
              label: "About",
              path: "/about",
            },
            {
              label: "Resources",
              path: "/resources",
            },
            {
              label: "Join",
              path: "/careers",
            },
            {
              label: "Contact",
              path: "/contact",
            },
          ],
        },
      ],
    },
  ];

  const handleNavItemClick = (path) => {
    navigate(path);
  };

  const handleDropdownItemClick = (path) => {
    const [basePath, hash] = path.split("#"); // Extract page and section

  if (location.pathname === basePath && hash) {
    // If already on the same page, scroll with offset
    setTimeout(() => {
      const section = document.getElementById(hash);
      if (section) {
        const yOffset = -100; // Adjust offset (negative for upward shift)
        const y = section.getBoundingClientRect().top + window.scrollY + yOffset;
        window.scrollTo({ top: y, behavior: "smooth" });
      }
    }, 100);
  } else {
    // Navigate first, then apply scroll after the page loads
    navigate(basePath);
    setTimeout(() => {
      const section = document.getElementById(hash);
      if (section) {
        const yOffset = -100; // Adjust offset for navbar
        const y = section.getBoundingClientRect().top + window.scrollY + yOffset;
        window.scrollTo({ top: y, behavior: "smooth" });
      }
    }, 500); // Delay to ensure page load
  }
  };
  

  const handleBookDemoClick = () => {
    navigate("/bookdemo");
  };

  const toggleDropdown = () => {
    setShowMenu(false);
    setShowDropdown(!showDropdown);
  };

  const handleMenuKeyDown = (event) => {
    if (event.key === "Enter") {
      setShowDropdown(true);
    }
  };

  const handleKeyDown = (event, path) => {
    if (event.key === "Enter") {
      navigate(path);
    }
  };

  return (
    <section className="navbar-wrapper">
      <div className="navbar">
        <figure className="innaxys-logo">
          <a href="/">
            <img src={NavLogo} alt="Nav Logo" />
          </a>
        </figure>
        <div className="nav-options-container">
          <ul className="nav-options">
            {navItems.map((item, index) => (
              <li
                className={`nav-option ${
                  isActivePath([
                    item.path,
                    ...item.dropdownSections.map((section) =>
                      section.items.map((i) => i.path)
                    ),
                  ])
                    ? "active-nav"
                    : ""
                }`}
                key={index}
                onMouseEnter={() => setHoveredItem(index)}
                onMouseLeave={() => setHoveredItem(null)}
                onFocus={() => setHoveredItem(index)}
              >
                <div
                  className="nav-item-container"
                  onClick={() => handleNavItemClick(item.path)}
                  onKeyDown={(event) => handleKeyDown(event, item.path)}
                  tabIndex="0"
                >
                  {item.name[currentLanguage]}
                  <span className="nav-caret">
                    <TbCaretDownFilled className="caret" size={20} />
                  </span>
                </div>

                {/* Hovered Item Dropdown */}
                {hoveredItem === index && (
                  <div
                    className={`desktop-nav-dropdown-container desktop-nav-dropdown-container-${item.name.en
                      .toLowerCase()
                      .replace(/\s+/g, "-")}`}
                  >
                    <ul className="desktop-nav-dropdown">
                      {item.dropdownSections.map((section, sectionIndex) => (
                        <li key={sectionIndex}>
                          
                          {section.header && ( // Only render if description exists
                                    <div className="dropdown-header">
                                    {section.header}
                                  </div>
                                  )}
                          {section.items.map((dropdownItem, i) => {
                            const IconComponent = iconMap[dropdownItem.icon];
                            const hasVisibleItems =
                              dropdownItem.subitems?.length > 0 ||
                              dropdownItem.path;

                            const isIndustriesItem =
                              item.name.en === "Industries";

                            if (!hasVisibleItems) return null;

                            return (
                              <li
                                className={`desktop-dropdown-nav-option ${
                                  location.pathname === dropdownItem.path
                                    ? "active-dropdown-item"
                                    : ""
                                } 
                ${
                  isIndustriesItem ? "nav-item-industries" : ""
                } dropdown-item-${dropdownItem.label
                                  .toLowerCase()
                                  .replace(/\s+/g, "-")}`}
                                key={i}
                                onClick={() =>
                                  handleDropdownItemClick(
                                    dropdownItem.link || dropdownItem.path
                                  )
                                }
                                onKeyDown={(event) =>
                                  handleKeyDown(
                                    event,
                                    dropdownItem.link || dropdownItem.path
                                  )
                                }
                                tabIndex="0"
                              >
                                <div className="dropdown-subheader-container">
                                  {IconComponent && <IconComponent size={26} />}
                                  <span>{dropdownItem.label}</span>
                                  {dropdownItem.description && ( // Only render if description exists
                                    <p className="solution-description">
                                      {dropdownItem.description}
                                    </p>
                                  )}
                                </div>

                                {dropdownItem.subitems && (
                                  <ul>
                                    {dropdownItem.subitems.map(
                                      (subItem, subIndex) => (
                                        <li
                                          key={subIndex}
                                          onClick={() =>
                                            handleDropdownItemClick(
                                              subItem.link
                                            )
                                          }
                                          onKeyDown={(event) =>
                                            handleKeyDown(event, subItem.link)
                                          }
                                          className={`sub-dropdown-item sub-dropdown-item-${subItem.label
                                            .toLowerCase()
                                            .replace(/\s+/g, "-")}`}
                                          tabIndex="0"
                                        >
                                          {subItem.label}
                                        </li>
                                      )
                                    )}
                                  </ul>
                                )}
                              </li>
                            );
                          })}
                        </li>
                      ))}
                    </ul>
                  </div>
                )}
              </li>
            ))}
          </ul>
        </div>
        <div className="nav-right-side">
          {/* <button
            className="language-button nav-button"
            onClick={toggleLanguageModal}
          >
            <TbWorld size={20} />
          </button> */}
          <button
            onClick={handleBookDemoClick}
            className={`cta-button nav-button ${showMenu ? "hidden" : ""}`}
          >
            {currentLanguage === "en" ? "Request a demo" : "Réserver une démo"}
          </button>

          <i
            className="hamburger-menu nav-icon"
            onClick={toggleDropdown}
            onKeyDown={handleMenuKeyDown}
            tabIndex="0"
          >
            <div id="nav-icon3">
              <span></span>
              <span></span>
              <span></span>
              <span></span>
            </div>
          </i>
        </div>
      </div>

      {/* Overlay for the dropdown */}
      <div
        className={`overlay ${showDropdown ? "visible" : ""}`}
        onClick={() => setShowDropdown(false)}
      ></div>

      {showDropdown && (
        <NavDropdownModal
          onClose={() => setShowDropdown(false)}
          handleNavItemClick={handleNavItemClick}
          handleBookDemoClick={handleBookDemoClick}
        />
      )}

      {/* Render the LanguageModal */}
      {showLanguageModal && (
        <LanguageModal
          currentLanguage={currentLanguage}
          handleLanguageChange={handleLanguageChange}
          toggleLanguageModal={toggleLanguageModal}
        />
      )}
    </section>
  );
};

export default Nav;
